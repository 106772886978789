<!--
 * @Autor: yzr
 * @Date: 2021-07-20 10:37:32
 * @LastEditors: yzr
 * @LastEditTime: 2021-09-09 10:59:52
 * @Description: 分利协议主体列表
-->
<script>
import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';
import * as utils from '../../../utils/index';

export default {
  name: 'modules',
  extends: TablePage,
  data() {
    return {
    //   requestType: 'GET',
    //   requestUrl: '/cps/v1/barcode/scanCodeRecord/tj/requestCodeInfo',
      params: {
      },
      formConfig: {},
    };
  },
  async created() {
    await this.getConfigList('bar_select');
  },
  mounted() {
  },
  methods: {

  },

};
</script>
