/**
   * @desc 获取当前时间 yyyy-MM-DD
  */
export function getCurrentDate() {
  const date = new Date();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const compleMonth = Number(month) < 10 ? `0${month}` : month;
  const compleDay = Number(month) < 10 ? `0${day}` : day;
  return {
    year: date.getFullYear(),
    month: compleMonth,
    day: compleDay,
    hour: date.getHours(),
    seconds: date.getSeconds(),
    minutes: date.getMinutes(),
  };
}

/**
   * @desc 格式化时间
  */
export function dateFormat(newfmt, newdate) {
  let ret;
  const date = new Date(newdate);
  let fmt = newfmt;
  const opt = {
    'Y+': date.getFullYear().toString(), // 年
    'm+': (date.getMonth() + 1).toString(), // 月
    'd+': date.getDate().toString(), // 日
    'H+': date.getHours().toString(), // 时
    'M+': date.getMinutes().toString(), // 分
    'S+': date.getSeconds().toString(), // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  Object.keys(opt).forEach((k) => {
    ret = new RegExp(`(${k})`).exec(fmt);
    if (ret) {
      fmt = fmt.replace(
        ret[1],
        ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'),
      );
    }
  });
  return fmt;
}
